import React, {useEffect, useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Slide from '../../components/Slide/Slide';
import './Home.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4,
  },
  tablet: {
    breakpoint: {max: 1280, min: 464},
    items: 4,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

export default function Home() {
  const [instagramData, setInstagramData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchData() {
    // eslint-disable-next-line max-len
    const token = 'IGAANwqqZAlWppBZAE9RaTNMTEtpNVJVeVhyRnVTU0UzblhKdnlXZATBQb1BjNGhobU9xVjUxbTVXakxxV3RFMkgwelVuVGp4eDBJS29vb29aSHFrLUpjdk5JM0dSdUJlRmlSaFVDVU0taXJ2ZADVmeml2cm9JSmJ6cmt3UjdjX1dVbwZDZD'; // Substitua pelo token atualizado
    const url = `https://graph.instagram.com/me/media?fields=media_url,media_type,permalink&access_token=${token}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Erro na API: ${response.status} 
        - ${response.statusText}`);
      }

      const feed = await response.json();
      const filteredData = feed.data.filter((el) => el.media_type === 'IMAGE');
      setInstagramData(filteredData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Slide />

      <div className="page-divider">
        <div className="page-title">
          <h1>Notícias</h1>
        </div>
      </div>

      <div className="home-news-container">
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p className="error">Erro ao carregar o feed: {error}</p>
        ) : (
          <Carousel
            swipeable
            draggable
            containerClass="news-carousel"
            itemClass="home-news-card"
            ssr
            responsive={responsive}
          >
            {instagramData.map((post) => (
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
                key={post.id}
              >
                <img src={post.media_url} alt="Instagram post" />
              </a>
            ))}
          </Carousel>
        )}
      </div>

      <div className="page-divider">
        <div className="page-title">
          <h1>Depoimentos</h1>
        </div>
      </div>

      <div className="page-wrap home-brief-container">
        <p className="center-text">
          Nossa meta é oferecer sempre o melhor serviço educacional, sendo parte
          da nossa missão formar profissionais críticos, responsáveis e
          comprometidos com o desenvolvimento social e humano. Desta forma,
          temos como objetivo promover o conhecimento com inovação, nos limites
          da ética que contribuam para a consolidação do desenvolvimento
          regional e do nosso país.
        </p>
        <div className="depoimentos">
          <div className="depoimento-container">
            <video width="320" height="240" controls>
              <source src="/assets/depoimento1.mp4" type="video/mp4" />
              Seu navegador não suporta vídeos.
            </video>
            <p>
              <b>J. P. Rizzi</b>
            </p>
            <p>Graduado em Administração - Facisa</p>
            <p>Empresário no Ramo Gastronômico</p>
          </div>
          <div className="depoimento-container segundo-depoimento">
            <video width="320" height="240" controls>
              <source src="/assets/depoimento2.mp4" type="video/mp4" />
              Seu navegador não suporta vídeos.
            </video>
            <p>
              <b>D. C. Scopel</b>
            </p>
            <p>Graduada em Administração - Facisa</p>
          </div>
        </div>
      </div>

      <div className="page-divider">
        <div className="page-title">
          <h1>Instalações</h1>
        </div>
      </div>

      <div className="page-wrap home-instalations-container">
        <p className="center-text">
          Venha estudar na melhor Faculdade do Sul e Extremo Sul da Bahia, com
          excelência comprovada pelo MEC. A FACISA possui um campus repleto de:
          laboratórios e salas climatizadas. Venha conhecer de perto!
        </p>
        <div className="instalacoes-container">
          <div className="instalacoes-list list-left">
            <p>Salas Climatizadas</p>
            <p>Biblioteca</p>
            <p>Laboratório de T.I</p>
            <p>Estúdio de Filmagens</p>
            <p>Cozinha Profissional totalmente equipada</p>
            <p>Sala de degustação climatizada</p>
          </div>
          <iframe
            className="video-instalacoes"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0rU8bdP4ylU?autoplay=1&mute=1&loop=1&controls=0&playlist=0rU8bdP4ylU"
            title="Instalações"
            allow="autoplay; encrypted-media"
          ></iframe>

          <div className="instalacoes-list list-right">
            <p>Clínica de Odontologia</p>
            <p>Sala de Anatomia / Microbiologia</p>
            <p>Laboratórios de Radiologia</p>
            <p>Sala de Raios-X</p>
            <p>Auditório com capacidade para 500 pessoas</p>
            <p>Quadra poliesportiva coberta</p>
            <p>Estacionamento</p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
