export default [
  {
    imagem: '/assets/images/adm.png',
    curso: 'Administraçao',
    descricao: `O Curso apresenta uma grade curricular
     construída para atender as exigências e inovar no mercado de trabalho.`,
    semestres: 8,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/31OWv7f',
  },
  {
    imagem: '/assets/images/direito.png',
    curso: 'Direito',
    descricao: `O Curso apresenta uma estrutura curricular avançada 
    que contempla disciplinas fundamentais e
     profissionalizantes voltadas para a formação 
     de um profissional com maior senso crítico e 
     consciente de suas responsabilidades como operador jurídico.`,
    semestres: 10,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/38BcbMH',
  },
  {
    imagem: '/assets/images/enfermagem.png',
    curso: 'Enfermagem',
    descricao: `O Curso apresenta na sua estrutura curricular 
    disciplinas fundamentais e profissionalizantes que 
    objetivam formar o profissional enfermeiro com uma 
    visão crítica e reflexiva sobre o contexto hitórico-social 
    do indivíduo e da população na perspectiva da consolidação 
    do Sistema Único de Saúde.
    `,
    semestres: 10,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/3e6Llx5',
  },
  {
    imagem: '/assets/images/farmacia.png',
    curso: 'Farmácia',
    descricao: `O egresso do Curso de Farmácia da FACISA será 
    um FARMACÊUTICO com formação centrada nos fármacos, 
    nos medicamentos e na assistência farmacêutica e, 
    de forma integrada, com formação em análises clínicas 
    e toxicológicas, em cosméticos e em alimentos, 
    em prol do cuidado à saúde do indivíduo, da família e da comunidade.`,
    semestres: 10,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/31Rwmoc',
  },
  {
    imagem: '/assets/images/gastronomia.png',
    curso: 'Gastronomia',
    descricao: `O referido curso apresenta uma grade 
    curricular completa, abrangendo diversas áreas da 
    gastronomia, integrando os alunos no mercado de trabalho, 
    de forma a estarem aptos ao exercício desta profissão, 
    com ética e compromisso profissional e social.`,
    semestres: 5,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/3e7JTu8',
  },
  {
    imagem: '/assets/images/medvet.png',
    curso: 'Medicina Veterinária',
    descricao: `O curso de Medicina Veterinária da FACISA foi 
    criado com o objetivo de formar um bacharel 
    em Medicina Veterinária generalista, 
    crítico e capaz de atuar na promoção da saúde 
    e na produção animal, em benefício da sociedade.
    .`,
    semestres: 10,
    periodo: 'Integral',
    linkwhats: 'https://bit.ly/3falcP6',
  },
  {
    imagem: '/assets/images/odonto.png',
    curso: 'Odontologia',
    descricao: `Norteado dentro de um conceito de qualidade de constante 
    aprendizado, os nossos futuros cirurgiões dentistas 
    não se restringirão aos aspectos apenas das etiologias, 
    mas, além da formação técnico-científica estarão aptos a exercer 
    a profissão conscientes do seu agir profissional, 
    na promoção de saúde fundamentada nos preceitos éticos, 
    morais, voltadas para a realidade da população brasileira.`,
    semestres: 10,
    periodo: 'Integral',
    linkwhats: 'https://bit.ly/2AAsSev',
  },
  {
    imagem: '/assets/images/radiologia.png',
    curso: 'Radiologia',
    descricao: `O curso Superior de Tecnologia em Radiologia da Faculdade de 
    Ciências Sociais Aplicadas (FACISA) tem como principal 
    objetivo formar profissionais com habilidades 
    e competências para atuação nas mais diversas áreas 
    do radiodiagnóstico, imagiologia , terapia e indústria 
    para que possam suprir as exigências do mercado de trabalho 
    que se encontra cada vez mais competitivo.`,
    semestres: 6,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/3gxjZBQ',
  },
  {
    imagem: '/assets/images/serviço.png',
    curso: 'Serviço Social',
    descricao: `O curso de Serviço Social da FACISA, é reconhecido pelo 
    MEC com nota 4, igualando-se às grandes universidades do país,
     busca formar profissionais Assistentes Sociais com capacidade 
     de análise da realidade social, de fazer intervenções junto
      às demandas presentes na sociedade, e de formular respostas 
      profissionais para o enfrentamento das desigualdades sociais 
      considerando as possibilidades de avanço no campo dos direitos 
      sociais expressos nos diversos espaços sócias.`,
    semestres: 8,
    periodo: 'Noturno',
    linkwhats: 'https://bit.ly/3f8Jrx4',
  },
];
